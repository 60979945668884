import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
 
 
let routes= [
  // {
  //   path: '/',
  //   name: 'defautlogin',
  //   //使用import可以路由懒加载，如果不使用，太多组件一起加载会造成白屏
  //   component: () => import('../views/Login.vue')
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/printedit',
    name: 'printedit',
    component: () => import('../views/PrintEdit.vue')
  },
  {
    path: '/picklabel',
    name: 'picklabel',
    component: () => import('../views/Picklabel.vue')
  },
  {
    path: '/printpage',
    name: 'printpage',
    component: () => import('../views/PrintPage.vue')
  },
  {
    path: '/homefull/',
    name: 'fullmain',
    component: () => import('../views/homefull/FullMain.vue'),
    children: [
      {
        path: 'datafull',
        name: 'datafull',
        component: () => import('../views/homefull/DataFull.vue')
      },
      {
        path: 'sortfull',
        name: 'sortfull',
        component: () => import('../views/homefull/SortFull.vue')
      },
      {
        path: 'deliveryfull',
        name: 'deliveryfull',
        component: () => import('../views/homefull/DeliveryFull.vue')
      },
      {
        path: 'sourcefull',
        name: 'sourcefull',
        component: () => import('../views/homefull/SourceFull.vue')
      },
    ]
  },
  {
    path: '/homefullHm/',
    name: 'fullmainHm',
    component: () => import('../views/homefullHm/FullMain.vue'),
    children: [
      {
        path: 'datafullHm',
        name: 'datafullHm',
        component: () => import('../views/homefullHm/DataFull.vue')
      },
      {
        path: 'sortfullHm',
        name: 'sortfullHm',
        component: () => import('../views/homefullHm/SortFull.vue')
      },
      {
        path: 'deliveryfullHm',
        name: 'deliveryfullHm',
        component: () => import('../views/homefullHm/DeliveryFull.vue')
      },
      {
        path: 'sourcefullHm',
        name: 'sourcefullHm',
        component: () => import('../views/homefullHm/SourceFull.vue')
      },
    ]
  },
  {
    path: '/',
    name: 'mainpage',
    component: () => import('../views/Mainpage.vue'),
    children: [
      // {
      //   path: '',
      //   name: 'redirect',
      //   redirect: '/login',
      //   // component: () => import('../views/Home.vue'),
      // },
      // {
      //   path: 'home',
      //   name: 'home',
      //   component: () => import('../views/Home.vue'),
      // },
      {
        path: '',
        name: 'home',
        component: () => import('../views/Home.vue'),
      },
      {
        path: 'goods/management/quotationmanage',
        name: 'quotationmanage',
        component: () => import('../views/goods/management/QuotationManage.vue')
      },
      {
        path: 'goods/management/cycleprice',
        name: 'cycleprice',
        component: () => import('../views/goods/management/CyclePrice.vue')
      },
      {
        path: 'goods/management/warehouse',
        name: 'warehouse',
        component: () => import('../views/goods/management/WareHouse.vue')
      },
      {
        path: 'goods/management/dcpro',
        name: 'dcpro',
        component: () => import('../views/goods/management/Dcpro.vue')
      },
      {
        path: 'goods/management/goodsunit',
        name: 'goodsunit',
        component: () => import('../views/goods/management/GoodsUnit.vue')
      },
      {
        path: 'goods/management/sortmanage',
        name: 'sortmanage',
        component: () => import('../views/goods/management/SortManage.vue')
      },
      {
        path: 'goods/management/goodsremark',
        name: 'goodsremark',
        component: () => import('../views/goods/management/GoodsRemark.vue')
      },
      {
        path: 'goods/management/newdemand',
        name: 'newdemand',
        component: () => import('../views/goods/management/NewDemand.vue')
      },
      {
        path: 'goods/management/supplierproducer',
        name: 'supplierproducer',
        component: () => import('../views/goods/management/SupplierProducer.vue')
      },
      {
        path: 'goods/management/commissionrule',
        name: 'commissionrule',
        component: () => import('../views/goods/management/CommissionRule.vue')
      },
      {
        path: 'goods/management/offerrecord',
        name: 'offerrecord',
        component: () => import('../views/goods/management/OfferRecord.vue')
      },
      {
        path: 'goods/contractprice/discountprice',
        name: 'discountprice',
        component: () => import('../views/goods/contractprice/DiscountPrice.vue')
      },
      {
        path: 'goods/contractprice/upprice',
        name: 'upprice',
        component: () => import('../views/goods/contractprice/UpPrice.vue')
      },
      {
        path: 'buzvision/goodsnorm',
        name: 'goodsnorm',
        component: () => import('../views/buzvision/GoodsNorm.vue')
      },
      {
        path: 'order/orderlist',
        name: 'orderlist',
        component: () => import('../views/order/OrderList.vue')
      },
      {
        path: 'order/orderpro',
        name: 'orderpro',
        component: () => import('../views/order/Orderpro.vue')
      },
      {
        path: 'order/createorder',
        name: 'createorder',
        component: () => import('../views/order/CreateOrder.vue')
      },
      {
        path: 'order/combineorder',
        name: 'combineorder',
        component: () => import('../views/order/CombineOrder.vue')
      },
      {
        path: 'order/goodsdepo',
        name: 'goodsdepo',
        component: () => import('../views/order/GoodsDepo.vue')
      },
      {
        path: 'order/changeordercheck',
        name: 'changeordercheck',
        component: () => import('../views/order/ChangeorderCheck.vue')
      },
      {
        path: 'order/cusfields',
        name: 'cusfields',
        component: () => import('../views/order/CusFields.vue')
      },
      {
        path: 'order/unorderbuyer',
        name: 'unorderbuyer',
        component: () => import('../views/order/UnorderBuyer.vue')
      },
      {
        path: 'data/cockpit/salecockpit',
        name: 'salecockpit',
        component: () => import('../views/data/cockpit/SaleCockpit.vue')
      },
      {
        path: 'supplychain/purchase/purchasetask',
        name: 'purchasetask',
        component: () => import('../views/supplychain/purchase/PurchaseTask.vue')
      },
      {
        path: 'supplychain/purchase/purchasebill',
        name: 'purchasebill',
        component: () => import('../views/supplychain/purchase/PurchaseBill.vue')
      },
      {
        path: 'supplychain/purchase/querytask',
        name: 'querytask',
        component: () => import('../views/supplychain/purchase/QueryTask.vue')
      },
      {
        path: 'supplychain/purchase/queryoffer',
        name: 'queryoffer',
        component: () => import('../views/supplychain/purchase/QueryOffer.vue')
      },
      {
        path: 'supplychain/purchase/ledger',
        name: 'ledger',
        component: () => import('../views/supplychain/purchase/Ledger.vue')
      },
      {
        path: 'supplychain/purchase/suggest',
        name: 'suggest',
        component: () => import('../views/supplychain/purchase/suggest.vue')
      },
      {
        path: 'supplychain/pick/picktask',
        name: 'picktask',
        component: () => import('../views/supplychain/pick/PickTask.vue')
      },
      {
        path: 'supplychain/pick/pickbills',
        name: 'pickbills',
        component: () => import('../views/supplychain/pick/PickBills.vue')
      },
      {
        path: 'supplychain/pick/pickdiff',
        name: 'pickdiff',
        component: () => import('../views/supplychain/pick/PickDiff.vue')
      },
      {
        path: 'supplychain/sort/sortprogress',
        name: 'sortprogress',
        component: () => import('../views/supplychain/sort/SortProgress.vue')
      },
      {
        path: 'supplychain/sort/sortdetail',
        name: 'sortdetail',
        component: () => import('../views/supplychain/sort/SortDetail.vue')
      },
      {
        path: 'supplychain/sort/sortdata',
        name: 'sortdata',
        component: () => import('../views/supplychain/sort/SortData.vue')
      },
      {
        path: 'supplychain/sort/sortperform',
        name: 'sortperform',
        component: () => import('../views/supplychain/sort/SortPerform.vue')
      },
      {
        path: 'supplychain/sort/performrule',
        name: 'performrule',
        component: () => import('../views/supplychain/sort/PerformRule.vue')
      },
      {
        path: 'supplychain/delivery/deliverytask',
        name: 'deliverytask',
        component: () => import('../views/supplychain/delivery/DeliveryTask.vue')
      },
      {
        path: 'supplychain/delivery/routecardriver',
        name: 'routecardriver',
        component: () => import('../views/supplychain/delivery/RouteCardriver.vue')
      },
      {
        path: 'supplychain/delivery/route',
        name: 'route',
        component: () => import('../views/supplychain/delivery/Route.vue')
      },
      {
        path: 'supplychain/delivery/driver',
        name: 'driver',
        component: () => import('../views/supplychain/delivery/Driver.vue')
      },
      {
        path: 'supplychain/delivery/truck',
        name: 'truck',
        component: () => import('../views/supplychain/delivery/Truck.vue')
      },
      {
        path: 'supplychain/delivery/deliverydiff',
        name: 'deliverydiff',
        component: () => import('../views/supplychain/delivery/DeliveryDiff.vue')
      },
      {
        path: 'supplychain/source/examrep',
        name: 'examrep',
        component: () => import('../views/supplychain/source/ExamRep.vue')
      },
      {
        path: 'supplychain/purchase/purchasebilldetail',
        name: 'purchasebilldetail',
        component: () => import('../views/supplychain/purchase/PurchaseBillDetail.vue')
      },
      {
        path: 'supplychain/container/containerlist',
        name: 'containerlist',
        component: () => import('../views/supplychain/container/ContainerList.vue')
      },
      {
        path: 'supplychain/container/inoutrecord',
        name: 'inoutrecord',
        component: () => import('../views/supplychain/container/InoutRecord.vue')
      },
      {
        path: 'supplychain/container/custloan',
        name: 'custloan',
        component: () => import('../views/supplychain/container/CustLoan.vue')
      },
      {
        path: 'inoutstock/inmanage/buyin',
        name: 'buyin',
        component: () => import('../views/inoutstock/inmanage/BuyIn.vue')
      },
      {
        path: 'inoutstock/inmanage/custreturn',
        name: 'custreturn',
        component: () => import('../views/inoutstock/inmanage/CustReturn.vue')
      },
      {
        path: 'inoutstock/inmanage/inadjustbill',
        name: 'inadjustbill',
        component: () => import('../views/inoutstock/inmanage/InAdjustbill.vue')
      },
      {
        path: 'inoutstock/inmanage/purchasein',
        name: 'purchasein',
        component: () => import('../views/inoutstock/inmanage/PurchaseIn.vue')
      },
       {
        path: 'inoutstock/inmanage/tripartiteBuyin',
        name: 'tripartiteBuyin',
        component: () => import('../views/inoutstock/inmanage/TripartiteBuyin.vue')
      },
      {
        path: 'inoutstock/outmanage/saleout',
        name: 'saleout',
        component: () => import('../views/inoutstock/outmanage/SaleOut.vue')
      },
      {
        path: 'inoutstock/outmanage/soldout',
        name: 'soldout',
        component: () => import('../views/inoutstock/outmanage/SoldOut.vue')
      },
      {
        path: 'inoutstock/outmanage/buyrefund',
        name: 'buyrefund',
        component: () => import('../views/inoutstock/outmanage/BuyRefund.vue')
      },
      {
        path: 'inoutstock/segment/segmentplan',
        name: 'segmentplan',
        component: () => import('../views/inoutstock/segment/SegmentPlan.vue')
      },
      {
        path: 'inoutstock/segment/segmentbill',
        name: 'segmentbill',
        component: () => import('../views/inoutstock/segment/SegmentBill.vue')
      },
      {
        path: 'inoutstock/segment/process',
        name: 'process',
        component: () => import('../views/inoutstock/segment/Process.vue')
      },
      {
        path: 'inoutstock/segment/processbill',
        name: 'processbill',
        component: () => import('../views/inoutstock/segment/ProcessBill.vue')
      },
      {
        path: 'inoutstock/stockaccount/inventory',
        name: 'inventory',
        component: () => import('../views/inoutstock/stockaccount/Inventory.vue')
      },
      {
        path: 'inoutstock/stockaccount/stocklog',
        name: 'stocklog',
        component: () => import('../views/inoutstock/stockaccount/StockLog.vue')
      },
      {
        path: 'inoutstock/stockaccount/virtualinout',
        name: 'virtualinout',
        component: () => import('../views/inoutstock/stockaccount/VirtualInout.vue')
      },
      {
        path: 'inoutstock/stockaccount/stockadjustbill',
        name: 'stockadjustbill',
        component: () => import('../views/inoutstock/stockaccount/StockAdjustBill.vue')
      },
      {
        path: 'inoutstock/stockaccount/indetail',
        name: 'indetail',
        component: () => import('../views/inoutstock/stockaccount/Indetail.vue')
      },
      {
        path: 'inoutstock/stockaccount/outdetail',
        name: 'outdetail',
        component: () => import('../views/inoutstock/stockaccount/Outdetail.vue')
      },
      {
        path: 'inoutstock/stockaccount/inoutsummary',
        name: 'inoutsummary',
        component: () => import('../views/inoutstock/stockaccount/InoutSummary.vue')
      },
      {
        path: 'inoutstock/stockaccount/stockshiftplan',
        name: 'stockshiftplan',
        component: () => import('../views/inoutstock/stockaccount/StockshiftPlan.vue')
      },
      {
        path: 'inoutstock/stockaccount/stockshiftbill',
        name: 'stockshiftbill',
        component: () => import('../views/inoutstock/stockaccount/StockshiftBill.vue')
      },
      // {
      //   path: 'inoutstock/baseinfo/producer',
      //   name: 'producer',
      //   component: () => import('../views/inoutstock/baseinfo/Producer.vue')
      // },
      {
        path: 'market/marketing/limitlockprice',
        name: 'limitlockprice',
        component: () => import('../views/market/marketing/LimitLockprice.vue')
      },
      {
        path: 'market/marketing/combinegoods',
        name: 'combinegoods',
        component: () => import('../views/market/marketing/CombineGoods.vue')
      },
      {
        path: 'finance/customer/custclear',
        name: 'custclear',
        component: () => import('../views/finance/customer/CustClear.vue')
      },
      {
        path: 'finance/customer/custvouchers',
        name: 'custvouchers',
        component: () => import('../views/finance/customer/CustVouchers.vue')
      },
      {
        path: 'finance/customer/checkbill',
        name: 'checkbill',
        component: () => import('../views/finance/customer/CheckBill.vue')
      },
      {
        path: 'finance/supplier/supplierclear',
        name: 'supplierclear',
        component: () => import('../views/finance/supplier/SupplierClear.vue')
      },
      {
        path: 'finance/supplier/dirsupplierclear',
        name: 'dirsupplierclear',
        component: () => import('../views/finance/supplier/DirsupplierClear.vue')
      },
      {
        path: 'finance/supplier/needpaybill',
        name: 'needpaybill',
        component: () => import('../views/finance/supplier/NeedPaybill.vue')
      },
      {
        path: 'finance/fsettle/settleclear',
        name: 'settleclear',
        component: () => import('../views/finance/fsettle/SettleClear.vue')
      },
      {
        path: 'finance/fsettle/scgross',
        name: 'scgross',
        component: () => import('../views/finance/fsettle/ScGross.vue')
      },
      {
        path: 'org/staff/role',
        name: 'role',
        component: () => import('../views/org/staff/Role.vue')
      },
      {
        path: 'org/staff/stafforg',
        name: 'stafforg',
        component: () => import('../views/org/staff/StaffOrg.vue')
      },
      {
        path: 'org/staff/user',
        name: 'user',
        component: () => import('../views/org/staff/User.vue')
      },
      {
        path: 'org/orgnization/orgcombine',
        name: 'orgcombine',
        component: () => import('../views/org/orgnization/OrgCombine.vue')
      },
      {
        path: 'org/orgnization/orgmanage',
        name: 'orgmanage',
        component: () => import('../views/org/orgnization/Orgmanage.vue')
      },
      {
        path: 'org/orgnization/customer',
        name: 'customer',
        component: () => import('../views/org/orgnization/Customer.vue')
      },
      {
        path: 'org/orgnization/dinesheet',
        name: 'dinesheet',
        component: () => import('../views/org/orgnization/DineSheet.vue')
      },
      {
        path: 'org/orgnization/dinetp',
        name: 'dinetp',
        component: () => import('../views/org/orgnization/DineTp.vue')
      },
      {
        path: 'org/supplieraccount/supplierlist',
        name: 'supplierlist',
        component: () => import('../views/org/supplieraccount/SupplierList.vue')
      },
      {
        path: 'org/supplieraccount/suppliermanage',
        name: 'suppliermanage',
        component: () => import('../views/org/supplieraccount/SupplierManage.vue')
      },
      {
        path: 'org/supplieraccount/firmscsupplier',
        name: 'firmscsupplier',
        component: () => import('../views/org/supplieraccount/FirmScSupplier.vue')
      },
      {
        path: 'org/producer/producermanage',
        name: 'producermanage',
        component: () => import('../views/org/producer/ProducerManage.vue')
      },
      {
        path: 'org/settle/settlement',
        name: 'settlement',
        component: () => import('../views/org/settle/Settlement.vue')
      },
      {
        path: 'system/runset/systemconfig',
        name: 'systemconfig',
        component: () => import('../views/system/runset/SysConfig.vue')
      },
      {
        path: 'system/runset/runtime',
        name: 'runtime',
        component: () => import('../views/system/runset/Runtime.vue')
      },
      {
        path: 'system/runset/printtemplates',
        name: 'printtemplates',
        component: () => import('../views/system/runset/PrintTemplates.vue')
      },
      {
        path: 'system/runset/barnhouse',
        name: 'barnhouse',
        component: () => import('../views/system/runset/BarnHouse.vue')
      },
      {
        path: 'system/runset/barn',
        name: 'barn',
        component: () => import('../views/system/runset/Barn.vue')
      },
      {
        path: 'system/runset/freight',
        name: 'freight',
        component: () => import('../views/system/runset/Freight.vue')
      },
      {
        path: 'system/runset/cameraset',
        name: 'cameraset',
        component: () => import('../views/system/runset/CameraSet.vue')
      },
      {
        path: 'system/devops/unpackorders',
        name: 'unpackorders',
        component: () => import('../views/system/devops/UnpackOrders.vue')
      },
      {
        path: 'system/log/oplog',
        name: 'oplog',
        component: () => import('../views/system/log/OpLog.vue')
      },
      {
        path: 'system/wxapp/banner',
        name: 'banner',
        component: () => import('../views/system/wxapp/Banner.vue')
      },
      {
        path: 'monitor/monitororg',
        name: 'monitororg',
        component: () => import('../views/monitor/MonitorOrg.vue')
      },
      {
        path: 'monitor/realtime',
        name: 'realtime',
        component: () => import('../views/monitor/RealTime.vue')
      },
      {
        path: 'monitor/playback',
        name: 'playback',
        component: () => import('../views/monitor/PlayBack.vue')
      },
      {
        path: 'superuser/dict',
        name: 'dict',
        component: () => import('../views/superuser/Dict.vue')
      },
      {
        path: 'superuser/menulist',
        name: 'menulist',
        component: () => import('../views/superuser/MenuList.vue')
      },
      {
        path: 'superuser/package',
        name: 'package',
        component: () => import('../views/superuser/Package.vue')
      },
      {
        path: 'superuser/apply',
        name: 'apply',
        component: () => import('../views/superuser/Apply.vue')
      },
      {
        path: 'superuser/group',
        name: 'group',
        component: () => import('../views/superuser/Group.vue')
      },
      {
        path: 'superuser/partcenter',
        name: 'partcenter',
        component: () => import('../views/superuser/PartCenter.vue')
      },
      {
        path: 'superuser/site',
        name: 'site',
        component: () => import('../views/superuser/Site.vue')
      },
      {
        path: 'superuser/settlepack',
        name: 'settlepack',
        component: () => import('../views/superuser/SettlePack.vue')
      },
      {
        path: 'superuser/infor',
        name: 'infor',
        component: () => import('../views/superuser/Infor.vue')
      },
      {
        path: 'aftersale/afterlist',
        name: 'afterlist',
        component: () => import('../views/aftersale/AfterList.vue')
      },
      {
        path: 'report/purin/instat',
        name: 'instat',
        component: () => import('../views/report/purin/Instat.vue')
      },
      {
        path: 'report/purin/buystat',
        name: 'buystat',
        component: () => import('../views/report/purin/Buystat.vue')
      },
      {
        path: 'report/purin/pricetrend',
        name: 'pricetrend',
        component: () => import('../views/report/purin/PriceTrend.vue')
      },
      {
        path: 'report/sale/orgstat',
        name: 'orgstat',
        component: () => import('../views/report/sale/OrgStat.vue')
      },
      {
        path: 'report/sale/goodsvol',
        name: 'goodsvol',
        component: () => import('../views/report/sale/GoodsVol.vue')
      },
      {
        path: 'report/sale/orderupstat',
        name: 'orderupstat',
        component: () => import('../views/report/sale/OrderupStat.vue')
      },
      {
        path: 'report/sale/unistat',
        name: 'unistat',
        component: () => import('../views/report/sale/UniStat.vue')
      },
      {
        path: 'report/sale/dinerest',
        name: 'dinerest',
        component: () => import('../views/report/sale/DineRest.vue')
      },
      {
        path: 'report/finreport/orgcheckroll',
        name: 'orgcheckroll',
        component: () => import('../views/report/finreport/OrgcheckRoll.vue')
      },
      {
        path: 'report/finreport/dinecheckdetail',
        name: 'dinecheckdetail',
        component: () => import('../views/report/finreport/DinecheckDetail.vue')
      },
      {
        path: 'report/finreport/orgcheckdetail',
        name: 'orgcheckdetail',
        component: () => import('../views/report/finreport/OrgcheckDetail.vue')
      },
      {
        path: 'report/finreport/suppliercheckdetail',
        name: 'suppliercheckdetail',
        component: () => import('../views/report/finreport/SuppliercheckDetail.vue')
      },
      {
        path: 'report/finreport/supplierchecksum',
        name: 'supplierchecksum',
        component: () => import('../views/report/finreport/SuppliercheckSum.vue')
      },
      {
        path: 'report/finreport/dinelunchcheck',
        name: 'dinelunchcheck',
        component: () => import('../views/report/finreport/DinelunchCheck.vue')
      },
      {
        path: 'report/finreport/taxratestat',
        name: 'taxratestat',
        component: () => import('../views/report/finreport/TaxrateStat.vue')
      },
      {
        path: 'report/short/segmentloss',
        name: 'segmentloss',
        component: () => import('../views/report/short/SegmentLoss.vue')
      },
      {
        path: 'report/short/processloss',
        name: 'processloss',
        component: () => import('../views/report/short/ProcessLoss.vue')
      },
      {
        path: 'report/short/pickloss',
        name: 'pickloss',
        component: () => import('../views/report/short/PickLoss.vue')
      },
      {
        path: 'report/short/stockshortbill',
        name: 'stockshortbill',
        component: () => import('../views/report/short/StockShortBill.vue')
      },
      {
        path: 'report/short/aftershort',
        name: 'aftershort',
        component: () => import('../views/report/short/AfterShort.vue')
      }
    ]
  }

  //{
    //配置404页面
    //path: '/:catchAll(.*)',
    //name: '404',
    //component: () => import(''),
  //}
]
// 路由
const router = createRouter({
    history: createWebHashHistory(),
    routes
})
// 导出
export default router 